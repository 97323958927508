export default {
    
	data() {
		   return {		   }

    },
    methods: {
    	degug(from, object){
    		console.log(from);
    		console.log(object);
    		
    		
    	},
    	formatChildren(id){
    			
    		if (id !=null && id ==1) return  'Нет'; 
    		if (id !=null && id ==2) return  'Есть, живем порознь'; 
    		if (id !=null && id ==3) return  'Есть, живем вместе'; 
    		 return  'Не указано'; 
    			
    	},
    	config(name){
    		switch(name){
    		case 'name': return 'DatingName';
     		case 'apiUrl': return process.env.NODE_ENV === 'production' ?  'https://datinplace.ru/api/v1/' : 'http://localhost/dating/api/v1/';
    		case 'wsUrl': return process.env.NODE_ENV === 'production' ?  'wss://datinplace.ru:27800' : 'ws://0.0.0.0:27800';
    		case 'yApiKey': return '434d7a57-c8d3-4add-b632-d6405b6e68b3';
    		case 'ySearchApiKey': return 'c3de2e83-690c-485a-b0ab-d3e4b8de8cd4';
    		case 'ddataApiKey': return 'c4b3d0880eb5791ac16ea170f685a80e1a8cf11f';
        		
    		}
    	},
    	pluralage(number){  
    		 let declension = ['год', 'года', 'лет'];
    		 let cases = [2, 0, 1, 1, 1, 2];  
    	    return declension[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
    	},
    	pluralMessages(number){  
   		 let declension = ['сообщение', 'сообщения', 'сообщений'];
   		 let cases = [2, 0, 1, 1, 1, 2];  
   	     return declension[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
   	},
    	checkPasswordMessage() {
    		return 'Недопустимый формат. Пароль должен содержат латинские буквы и цифры, не менее 8 символов';
    	},
    	setHeight(wrapper){
    		$("#app").css('height',$(window).height());
    		let bottomPanel = $(".bottompanel_wrapper").height();
    		let header = $(".header").outerHeight();
    		let marginTop = parseInt($("."+wrapper).css('margin-top').replace('px',''));
    		$("."+wrapper).css({'height':$(window).height() -  header - marginTop,'margin-bottom':'0px'}).addClass('fullheight');
    	},
    	checkPassword(password) {
    		var s_letters = "qwertyuiopasdfghjklzxcvbnm"; 
    	    var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; 
    	    var digits = "0123456789";
    	    var is_s = false;
    	    var is_b = false;
    	    var is_d = false;
    	    for (var i = 0; i < password.length; i++) {
    	      if (!is_s && s_letters.indexOf(password[i]) != -1) is_s = true;
    	      else if (!is_b && b_letters.indexOf(password[i]) != -1) is_b = true;
    	      else if (!is_d && digits.indexOf(password[i]) != -1) is_d = true;
    	    }
    	    
    		if (!is_s && !is_b || !is_d) return false;
    		return true;
    		
    	},
    	deg2rad(deg) {
    		  return deg * (Math.PI/180)
    		},
    	getDistanceFromLatLonInMeters(lat1,lon1,lat2,lon2) {
    		  var R = 6371; // Radius of the earth in km
    		  var dLat = (lat2-lat1) *  (Math.PI/180);  // deg2rad below
    		  var dLon = (lon2-lon1) *  (Math.PI/180); 
    		  var a = 
    		    Math.sin(dLat/2) * Math.sin(dLat/2) +
    		    Math.cos(lat1*  (Math.PI/180)) * Math.cos(lat2 *  (Math.PI/180)) * 
    		    Math.sin(dLon/2) * Math.sin(dLon/2)
    		    ; 
    		  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    		  var d = R * c * 1000; // Distance in km
    		  return d;
    		},
    	getlocationMixin(callbackSuccess, callbackError){
    			let result = {errorStr:'', location:null};
    		    //do we support geolocation
    		    if(!("geolocation" in navigator)) {
    		      result.errorStr = 'Геолокация недоступна';
    		      callbackError(result);
    		    }
    		    
    		    navigator.geolocation.getCurrentPosition(pos => {
    		       console.log('success get position');
    		       console.log(pos);
    		       
    		    	result.location = pos;
    		    	callbackSuccess(result);
    		      //this.updateUserLocation();
    		      //this.init()
    		    }, err => {
    	   		       console.log('error get position');
        		       console.log(err);
     
    		    	result.location = {coords:{latitude:'39.706640', longitude : '47.274677'}}
    		    	callbackError(err);
    		    	     //this.settings.location.center = [this.location.coords.latitude,this.location.coords.longitude];
    		      //this.updateUserLocation();
    		      //this.init(); 	  
    		      //this.gettingLocation = false;
    		      //this.errorStr = err.message;
    		    
    		    })
    		  
    	},
    	updateUserLocation(coords){
	  		this.$store.dispatch("user/updateloc", coords).then(
			        (data) => {
	        		    	if (data.success == 1){
	    		        	}
				        },
				        (error) => {
				        }
				 );
	  	},
    	clearUserLocation(){
	  		this.$store.dispatch("user/updateloc", {'latitude':0,'longitude':0}).then(
			        (data) => {
	        		    	if (data.success == 1){
	    		        	}
				        },
				        (error) => {
				        }
				 );
	  	},
    }
}
/*
 * iptables -A INPUT -p tcp --dport 27800 -j ACCEPT
 * /var/www/www-root/data/www/datinplace.ru/api/chatworker.php start -d
 */
