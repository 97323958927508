import UserService from '../services/user.service';

const userProfile = JSON.parse(localStorage.getItem('user'));

const initialState = userProfile
  ? { status: { loggedIn: true }, userProfile }
  : { status: { loggedIn: false }, user: null };

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    profile({ commit }) {
      return UserService.getProfile().then(
        user => {
     //     commit('profileSuccess', user);
          return Promise.resolve(user);
        },
        error => {
        	
          commit('infoerror',error.request.status);
          return Promise.reject(error);
        }
      );
    },
    delaccount({ commit }) {
//      alert("ok");
    	UserService.delaccount();
      commit('logout');
    },
    support({ commit }, form) {
        return UserService.support(form).then(
          answer => {
       //     commit('profileSuccess', user);
            return Promise.resolve(answer);
          },
          error => {
          	
            commit('infoerror',error.request.status);
            return Promise.reject(error);
          }
        );
      },
    changePhotoToMain({ commit }, id) {
    	  return UserService.changePhotoToMain(id).then(
    	          answer => {
    	       //     commit('profileSuccess', user);
    	            return Promise.resolve(answer);
    	          },
    	          error => {
    	          	
    	            commit('infoerror',error.request.status);
    	            return Promise.reject(error);
    	          }
    	        );
    },
    checkIn({ commit }, coordinates) {
  	  return UserService.checkIn(coordinates).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    updateloc({ commit }, coordinates) {
  	  return UserService.updateLoc(coordinates).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    checkOut({ commit }, placeId) {
  	  return UserService.checkOut(placeId).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    getPlaceInfo({ commit }, placeId) {
  	  return UserService.getPlaceInfo(placeId).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    getDefaultLocation({ commit }) {
  	  return UserService.getDefaultLocation().then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    deletePlace({ commit }, placeId) {
  	  return UserService.deletePlace(placeId).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    setActivePlace({ commit }, placeId) {
  	  return UserService.setActivePlace(placeId).then(
  	          answer => {
  	       //     commit('profileSuccess', user);
  	            return Promise.resolve(answer);
  	          },
  	          error => {
  	          	
  	            commit('infoerror',error.request.status);
  	            return Promise.reject(error);
  	          }
  	        );
  },
    savePhoto({ commit }, form) {

        return UserService.savePhoto(form).then(
          answer => {
       //     commit('profileSuccess', user);
            return Promise.resolve(answer);
          },
          error => {
          	
            commit('infoerror',error.request.status);
            return Promise.reject(error);
          }
        );
      },
    changepwd({ commit }, data){
    	  return UserService.changepwd(data).then(
  	            answer => {
  	         //     commit('profileSuccess', user);
  	              return Promise.resolve(answer);
  	            },
  	            error => {
  	              commit('infoerror',error.request.status);
  	              return Promise.reject(error);
  	            }
  	          );
    },
    requestChangeEmail({ commit }, data){
    	  return UserService.requestChangeEmail(data).then(
    	            answer => {
    	         //     commit('profileSuccess', user);
    	              return Promise.resolve(answer);
    	            },
    	            error => {
    	              commit('infoerror',error.request.status);
    	              return Promise.reject(error);
    	            }
    	          );
    },
    
    saveField({ commit }, field) {

          return UserService.saveField(field).then(
            answer => {
         //     commit('profileSuccess', user);
              return Promise.resolve(answer);
            },
            error => {
            	
              commit('infoerror',error.request.status);
              return Promise.reject(error);
            }
          );
        },

    deletePhoto({ commit }, id) {

          return UserService.deletePhoto(id).then(
            answer => {
              return Promise.resolve(answer);
            },
            error => {
              commit('infoerror',error.request.status);
              return Promise.reject(error);
            }
          );
        },
  },



  mutations: {
	  infoerror(state, code){
		  if (code == 401) {
		        state.status.loggedIn = false;
		        state.status.user = null;
			    localStorage.removeItem('user');	

		  }

	  },
	  getInfoFailure(state, code) {
		  if (code == 401) {
		        state.status.loggedIn = false;
		        state.status.user = null;
		    //    localStorage.removeItem('user');	
	  
		  }
    },
	 profilSuccess(state, user) {
    },
  }
};
